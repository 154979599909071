import React, { useEffect } from "react";
import Hero from "./hero/Hero";
import firebase from "firebase";

const Home = () => {
  const analytics = firebase.analytics();

  useEffect(()=>{
    analytics.logEvent("page_view", {
      page_name: "home",
      page_path: "/home",
    });
  },[])
  
  return (
    <>
      <Hero />
    </>
  );
};

export default Home;
