import React from "react";

const Head = () => {
  return (
    <>
      <section className="head">
        <div className="container flexSB">
          <div style={{justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column'}}>
            <img src={require('../../../assets/logoQPGTM.png')} width={150}  />
          </div>
          
          <div className="social" >
            <a href="https://www.facebook.com/Quick-PG-109687331777935" target="_blank"style={{textDecoration:'none'}}>
              <i className="fab fa-facebook-f icon"></i>
            </a> 
            <a href="https://www.instagram.com/quickpg/" target="_blank" style={{textDecoration:'none'}}> 
              <i className="fab fa-instagram icon"></i>
            </a>
            <a  style={{textDecoration:'none'}}>

            <i className="fab fa-twitter icon"></i>
            </a>
            <a  style={{textDecoration:'none'}}>

            <i className="fab fa-youtube icon"></i>
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Head;
