import React from "react";
import { NavLink } from "react-router-dom";
import Heading from "../../common/heading/Heading";
import "./Hero.css";

const Hero = () => {
  return (
    <>
      <section className="hero">
        <div className="container">
          <div className="row">
            <Heading
              subtitle="WELCOME TO QUICK PG"
              title="We will help you find the best PG for you!"
            />
            <p>We have connections with the best PGs in your required town.</p>
            <NavLink to="/about">
              <button className="primary-btn">
                LEARN MORE <i className="fa fa-long-arrow-alt-right"></i>
              </button>
            </NavLink>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
