import React, { useEffect } from "react";
import "./about.css";
import AboutCard from "./AboutCard";
import firebase from "firebase";
import AboutSelector from "./AboutSelector";
import {guestAbout,ownerAbout} from '../../dummydata'

const About = () => {
  const analytics = firebase.analytics();
  const [individual, setIndividual] = React.useState(1);

   const handleAboutChange = (event) => {
    setIndividual(event.target.value);
  };

  useEffect(() => {
    analytics.logEvent("page_view", {
      page_name: "about",
      page_path: "/about",
    });
  }, []);

  return (
    <>
      <AboutSelector individual={individual} handleChange={handleAboutChange}/>
      <AboutCard 
        headingTitle={individual == 1 ? "We provide you best budget friendly PGs at your forefingers." : "We provide you best management services and marketing facilities."}
        data={individual == 1? guestAbout : ownerAbout}
      />
    </>
  );
};

export default About;
