import React from "react";
import { blog } from "../../../dummydata";
import "./footer.css";
import firebase from "firebase";
import { validateEmail } from "../../../utils/functions";

const Footer = () => {
  const [emailEntered, setEmailEntered] = React.useState("");
  

  const handleSubmitEmail = async(e) => {
    e.preventDefault();
    if (!emailEntered) return alert("Please enter an email address first.");
    if (!validateEmail(emailEntered))
      return alert("You have entered an invalid email address!");
    const key = await firebase.database().ref("subscribers").push({
      email: emailEntered,
    }).key;
    await firebase.database().ref(`subscribers/${key}`).update({
      id: key,
    });
    await setEmailEntered("");
    await alert(
      "Subscribed successfully... You'll receive email notifications from now!"
    );
  }

  return (
    <>
    <hr/>
      <section className="newletter">
        <div className="container newsletterFlex">
          <div className="left newsletterRow">
            <h1>Newsletter - Stay tune and get the latest update</h1>
            <span>You'll get email notifications from us.</span>
          </div>
          <form className="right newsletterRow" onSubmit={handleSubmitEmail}>
            <input type="text" placeholder="Enter email address" onChange={(e)=>{e.preventDefault(); setEmailEntered(e.target.value)}} value={emailEntered}/>
            <i onClick={handleSubmitEmail} className="fa fa-paper-plane"></i>
          </form>
        </div>
      </section>
      <footer>
        <div className="container padding">
          <div className="box logo" >
            <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
            <img src={require('../../../assets/logoQPGTM.png')} width={200} />
            <div >
              <a href="https://www.facebook.com/Quick-PG-109687331777935" target="_blank"style={{textDecoration:'none'}}>
                <i className="fab fa-facebook-f icon"></i>
              </a>
              <a href="https://www.instagram.com/quickpg/" target="_blank" style={{textDecoration:'none'}}> 
                <i className="fab fa-instagram icon"></i>
              </a>
              <i className="fab fa-twitter icon"></i>
            </div>
            </div>
          </div>
          <div className="box link">
            <h3>Explore</h3>
            <ul>
              <li>About Us</li>
              <li>Contact us</li>
            </ul>
          </div>
          <div className="box link">
            <h3>Quick Links</h3>
            <ul>
              <li>Terms & Conditions</li>
              <li>Privacy</li>
              <li>Feedbacks</li>
            </ul>
          </div>
          <div className="box last">
            <h3>Have a Questions?</h3>
            <ul >
              <li>
                <i className="fa fa-map"></i>
                Sankar Madhab Path, Gandhi Nagar Panikhaiti, Guwahati, Assam
                781026
              </li>
              <li>
                <i className="fa fa-phone-alt"></i>
                +91 6003768308
              </li>
              <li>
                <i className="fa fa-phone-alt"></i>
                +91 6003018566
              </li>
              <li>
                <i className="fa fa-paper-plane"></i>
                info.quickpg@gmail.com
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div className="legal">
        <p>Copyright ©2022 All rights reserved</p>
      </div>
    </>
  );
};

export default Footer;
