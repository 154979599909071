import "./App.css";
import Header from "./components/common/header/Header";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  BrowserRouter,
} from "react-router-dom";
import About from "./components/about/About";
import Team from "./components/team/Team";
import Contact from "./components/contact/Contact";
import Footer from "./components/common/footer/Footer";
import Home from "./components/home/Home";
import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyAtOiv6XxLLgM9fvTAKbGRngHW-Ntc2wtE",
  authDomain: "quickpg-301fc.firebaseapp.com",
  projectId: "quickpg-301fc",
  storageBucket: "quickpg-301fc.appspot.com",
  messagingSenderId: "95420183436",
  appId: "1:95420183436:web:4ad15e7f3e76b11873dca9",
  measurementId: "G-Y2KB1VGPP3",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/team" component={Team} />
        <Route exact path="/contact" component={Contact} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
