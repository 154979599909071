import React, { useEffect } from "react";
import "./contact.css";
import firebase from "firebase";
import { validateEmail } from "../../utils/functions";

const Contact = () => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [number, setNumber] = React.useState("");
  const analytics = firebase.analytics();

  useEffect(()=>{
    analytics.logEvent("page_view", {
      page_name: "contact",
      page_path: "/contact",
    });
  },[])

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if(!name || !email || !message || !number) return alert("Please fill all the fields");
    if(!validateEmail(email)) return alert("Please enter a valid email address");
    const key = await firebase.database().ref("contacts").push({
      name,
      email,
      message,
      number,
    }).key;
    await firebase.database().ref(`contacts/${key}`).update({
      id: key,
    });
    await setName("");
    await setEmail("");
    await setMessage("");
    await setNumber("");
    await alert("Submitted successfully... We'll get back to you soon!");
  }

  const map =
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3579.842638500386!2d91.85927581503!3d26.201796783438887!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x512d4d63095a6a9a!2zMjbCsDEyJzA2LjUiTiA5McKwNTEnNDEuMyJF!5e0!3m2!1sen!2sin!4v1655315168875!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ';
  return (
    <>
      <section className="contacts padding">
        <div className="container shadow flexSB">
          <div className="left row">
            <iframe src={map}></iframe>
          </div>
          <div className="right row">
            <h1>Contact us</h1>
            <p>Let's check out your dream PG today!</p>

            <div className="items grid2">
              <div className="box">
                <h4>ADDRESS:</h4>
                <p>
                  Sankar Madhab Path, Gandhi Nagar Panikhaiti, Guwahati, Assam
                  781026
                </p>
              </div>
              <div
                className="box"
                style={{ wordBreak: "break-word", display: "inline-block" }}
              >
                <h4>EMAIL:</h4>
                <p>info.quickpg@gmail.com</p>
              </div>
              <div className="box">
                <h4>PHONE:</h4>
                <p>+91 6003768308</p>
                <p>or</p>
                <p>+91 6003018566</p>
              </div>
            </div>

            <form action="" onSubmit={handleSubmitForm}>
              <div className="flexSB">
                <input type="text" placeholder="Name" value={name} onChange={(e)=>{
                  setName(e.target.value);
                }}/>
                <input type="email" placeholder="Email" 
                value={email}
                onChange={(e)=>{
                  setEmail(e.target.value);
                }
              }
                />
              </div>
              <input type="text" placeholder="Phone number" 
              value={number}
              onChange={(e)=>{
                setNumber(e.target.value);
              }
            }
              />
              <textarea
                cols="30"
                rows="10"
                placeholder="Write your message here..."
                value={message}
                onChange={(e)=>{
                  setMessage(e.target.value);
                }
              }
              ></textarea>
              <button className="primary-btn" onClick={handleSubmitForm}>SEND MESSAGE</button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
