export const guestAbout = [
  {
    id: 1,
    cover:
      "https://img.icons8.com/external-xnimrodx-lineal-xnimrodx/344/external-fast-distribution-xnimrodx-lineal-xnimrodx.png",
    title: "Quick and easy!",
    desc: "We provide quick and easy access to search top PGs for you or your child.",
  },
  {
    id: 2,
    cover: "https://img.icons8.com/carbon-copy/344/budget.png",
    title: "Budget friendly!",
    desc: "We provide you list of budget friendly PGs for you or your child.",
  },
  {
    id: 3,
    cover: "https://img.icons8.com/dotty/344/easy.png",
    title: "Payment hassle-free!",
    desc: "Pay dues online and hassle-free.",
  },
];

export const ownerAbout = [
  {
    id: 1,
    cover:
      "https://img.icons8.com/external-xnimrodx-lineal-xnimrodx/344/external-fast-distribution-xnimrodx-lineal-xnimrodx.png",
    title: "Management made easy!",
    desc: "Manage your PG's logs and datas.",
  },
  {
    id: 2,
    cover: "https://img.icons8.com/pastel-glyph/344/web-marketing--v2.png",
    title: "Marketing Pro!",
    desc: "Never loose guests, we'll provide you with best marketing facilities.",
  },
  {
    id: 3,
    cover: "https://img.icons8.com/ios/344/appointment-reminders--v1.png",
    title: "Notify on food ready!",
    desc: "Send notifications when food is ready.",
  },
  {
    id: 4,
    cover: "https://img.icons8.com/dotty/344/easy.png",
    title: "Payment hassle-free!",
    desc: "Payment requests will be automated and simpler.",
  },
];

export const team = [
  {
    cover: "./images/team/t1.webp",
    name: "Ph.D Adrian Molises",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t2.webp",
    name: "Ph.D Arthur MaGregor",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t3.webp",
    name: "Ph.D Anna Hanzen",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t4.webp",
    name: "Ph.D Brian Wooden",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t5.webp",
    name: "Ph.D Adrian Molises",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t6.webp",
    name: "Ph.D Arthur MaGregor",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t7.webp",
    name: "Ph.D Anna Hanzen",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t8.webp",
    name: "Ph.D Brian Wooden",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
];
