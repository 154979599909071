import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Head from "./Head";

import "./header.css";

const Header = () => {
  const [click, setClick] = useState(false);

  return (
    <>
      <Head />
      <header>
        <nav className="flexSB">
          <ul
            className={click ? "mobile-nav" : "flexSB "}
            onClick={() => setClick(false)}
          >
            <li>
              <NavLink to="/" exact>
                Home
              </NavLink>
            </li>
           
            <li>
              <NavLink to="/about">About</NavLink>
            </li>
          
            <li>
              <NavLink to="/contact">Contact</NavLink>
            </li>
          </ul>
          <div className="start" onClick={()=>{alert("Coming soon!")}}>
            <div className="button">GET APP</div>
          </div>
          <button className="toggle" onClick={() => setClick(!click)}>
            {click ? (
              <i className="fa fa-times"> </i>
            ) : (
              <i className="fa fa-bars"></i>
            )}
          </button>
        </nav>
      </header>
    </>
  );
};

export default Header;
