import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./aboutSelector.css";

function AboutSelector({ handleChange, individual }) {
  return (
    <div className="aboutSelector">
      <p className="selectorText">I am a &#x2192;</p>
      <FormControl
        variant="filled"
        sx={{
          m: 1,
          minWidth: 220,
          backgroundColor: "#1eb2a6",
          borderRadius: "5px",
        }}
      >
        <InputLabel
          id="demo-simple-select-filled-label"
          style={{ color: "#ffffff" }}
        >
          Individual
        </InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={individual}
          onChange={handleChange}
          style={{ color: "#ffffff" }}
        >
          <MenuItem value={1}>Guest</MenuItem>
          <MenuItem value={2}>PG Owner</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

export default AboutSelector;
